.page {
    width: 100vw;
    max-width: 100%;
    min-height: 100dvh;
    height: 100dvh;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 255px;
    background-color: var(--backgroundcolor);
    position: absolute;
    overflow-y: hidden;
    top: 0;
    left: 0;
    padding-top: 2rem;
}
.topbar {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 95vw;
    max-width: 860px;
    border: 1px solid var(--primarycolor);
    padding: 1rem 0rem;
    border-radius: 1rem;
    background-color: var(--secondarycolor);
}

.top1 {
    grid-area: 1 / 1 / 2 / 7;
    border-bottom: 1px solid var(--primarycolor);
    padding-left: 1rem;
}
.top2 {
    grid-area: 2 / 1 / 3 / 3;
    padding-left: 1rem;
    padding-top: 1rem;
}
.top3 {
    grid-area: 2 / 3 / 3 / 5;
    padding-top: 1rem;
}
.top4 {
    grid-area: 2 / 5 / 3 / 7;
    padding-top: 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}
.top5 {
    grid-area: 3 / 1 / 4 / 7;
    padding-left: 1rem;
    border-bottom: 1px solid var(--primarycolor);
}
.top6 {
    grid-area: 4 / 1 / 5 / 3;
    padding-top: 1rem;
    padding-left: 1rem;
}
.top7 {
    grid-area: 4 / 3 / 5 / 4;
    padding-top: 1rem;
    min-width: 9rem;
}
.top8 {
    grid-area: 4 / 4 / 5 / 5;
    padding-top: 1rem;
}
.top9 {
    grid-area: 4 / 5 / 5 / 7;
    padding-top: 1rem;
}
.pic1 {
    margin-right: 0.25rem;
}
.controlbt {
    background-color: var(--secondarycolor);
    border-color: var(--fontcolor);
    padding: 0.25rem 0.5rem 0.25rem 0.25rem;
    margin-left: 0.5rem;
    display: flex;
    align-items: flex-end;
    border-radius: 8%;
    height: 2rem;
    cursor: pointer;
}
.controlfont {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    margin-left: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
}
.toolbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}
.testingblock {
    width: 95vw;
    max-width: 860px;
    height: calc(100vh - 20rem);
    overflow-y: scroll;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 5rem;
}
.select {
    background-color: var(--backgroundcolor);
    padding: 0rem 0.25rem;
    margin: 0rem 0.5rem;
}
.words {
    width: 430px;
}
.questionno {
    font-size: 1.25rem;
    width: 100%;
    margin-top: 1rem;
}
.question {
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.options {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
}
.questionpic {
    margin-top: 1rem;
    max-width: 100%;
}
.optionpic {
    margin-left: 1rem;
    max-width: 90%;
}
.goodScore {
    font-size: 2rem;
    color: var(--primarycolor);
}
.badScore {
    font-size: 2rem;
    color: var(--alarmcolor);
}
@media (max-width: 860px) {
    .topbar {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(6, 1fr);
        padding-bottom: 0rem;
    }
    .smalltopbar {
        grid-template-rows: repeat(5, 1fr) !important;
    }
    .top1 {
        grid-area: 1 / 1 / 2 / 7;
    }
    .top2 {
        grid-area: 2 / 1 / 3 / 4;
    }
    .top3 {
        grid-area: 2 / 4 / 3 / 7;
    }
    .top4 {
        grid-area: 4 / 1 / 5 / 7;
        padding-top: 0rem;
        padding-bottom: 1rem;
        padding-right: 1rem;
        justify-content: flex-end;
        border-bottom: 1px solid var(--primarycolor);
    }
    .top5 {
        grid-area: 3 / 1 / 4 / 7;
        border-bottom: none;
    }
    .top6 {
        grid-area: 5 / 1 / 6 / 4;
    }
    .top7 {
        grid-area: 5 / 4 / 6 / 7;
        padding-top: 1rem;
        min-width: 9rem;
    }
    .top8 {
        grid-area: 6 / 1 / 7 / 4;
        padding-top: 0rem;
        padding-left: 1rem;
    }
    .top9 {
        grid-area: 6 / 4 / 7 / 7;
        padding-top: 0rem;
    }
    .toolbar {
        flex-wrap: wrap;
    }
    .controlbts {
        order: 3;
        margin-top: 1rem;
        margin-left: -0.5rem;
        width: 40rem;
    }
    .noteradios {
        order: 1;
    }
    .selectQ {
        order: 2;
        margin-left: auto;
    }
}
@media (max-width: 440px) {
    .selectQ {
        height: 2.5rem;
        display: flex;
        align-items: flex-end;
    }
}

.loaderpage {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: color-mix(
        in srgb,
        var(--backgroundcolor) 70%,
        transparent 30%
    );
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader {
    width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat
        radial-gradient(
            circle closest-side,
            var(--fontcolor) 90%,
            var(--backgroundcolor)
        );
    background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
    background-size: calc(100% / 3) 50%;
    animation: l3 1s infinite linear;
}
@keyframes l3 {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%;
    }
    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%;
    }
    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%;
    }
    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%;
    }
}
