.background {
    width: 100vw;
    max-width: 100%;
    min-height: 100dvh;
    height: 100dvh;
    max-height: 100%;
    min-width: 255px;
    background-color: var(--backgroundcolor);
    position: relative;
    overflow: hidden;
}
.menu {
    position: absolute;
    left: 0;
    top: 0;
    padding: 2rem;
    display: flex;
    justify-content: flex-start;
    gap: 3rem;
    align-items: center;
    width: 100vw;
    max-width: 100%;
    z-index: 2;
    border-bottom: 1px solid var(--primarycolor);
    flex-wrap: wrap;
}
.title {
    font-size: 1.5rem;
    font-weight: 1000;
}
.menuitem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    font-size: 1.25rem;
}

.menuitem.selected::after {
    content: "";
    width: 5rem;
    height: 5px;
    background-color: var(--primarycolor);
}

.page {
    position: absolute;
    left: 0;
    right: 0;
    overflow-y: scroll;
    transform: translateX(100%);
    transition: all 1s ease-out;
    background-color: var(--backgroundcolor);
    top: calc(7rem + 1px);
    bottom: 0;
    padding: 1rem 2rem;
}
.page.selected {
    position: absolute;
    left: 0;
    right: 0;
    overflow-y: scroll;
    transform: translateX(0);
    z-index: 2;
}
@media (max-width: 690px) {
    .page {
        top: calc(12rem + 1px);
    }
}
.page1 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    max-width: 100rem;
}
.block1 {
    grid-area: 1 / 1 / 2 / 3;
}
.block2 {
    grid-area: 2 / 1 / 3 / 3;
}
.block3 {
    padding-left: 3rem;
    grid-area: 1 / 3 / 3 / 6;
}
.block4 {
    grid-area: 3 / 1 / 4 / 6;
    display: flex;
    justify-content: flex-start;
}
.frontline {
    position: relative;
    height: 100%;
    padding-bottom: 0.5rem;
}
.frontline textarea {
    position: absolute;
    top: 0px;
    left: 0px;
    border: 0;
    border-bottom: 1px solid var(--primarycolor);
    background: transparent;
    width: 100%;
    height: calc(100% - 0.25rem) !important;
    padding: 3.5rem 0 0 0;
    font-size: 1.25rem;
}
.frontline textarea:focus {
    border: none;
    outline: none;
    border-bottom: 1px solid var(--primarycolor);
}
.frontline label {
    position: absolute;
    top: 1rem;
    left: 0px;
    font-size: 1.25rem;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
    background-color: var(--backgroundcolor);
}
.uploadbt {
    width: 6rem;
    margin-top: 1rem;
    border-radius: 1.5rem;
    background-color: var(--backgroundcolor);
    border: 2px solid var(--primarycolor);
    font-size: 1.25rem;
    height: 3rem;
    transition: transform 1s ease;
}
.uploadbt:hover {
    cursor: pointer;
    background-color: var(--primarycolor);
    color: var(--backgroundcolor);
    transform: scale(1.1);
}

.page2 {
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 1rem;
    max-height: calc(100vh - 10rem);
}
.page2 table {
    width: clamp(60rem, 100%, 100rem);
    border-collapse: collapse;
}
.page2 td {
    padding: 1.5rem 0rem;
    font-size: 1rem;
}
.page2 tr {
    border-bottom: var(--primarycolor) solid 1px;
}
.clickablediv {
    color: var(--primarycolor);
    text-decoration: underline;
    cursor: pointer;
}
.hidden {
    display: none !important;
}
.editpage_back {
    position: fixed;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    background-color: color-mix(
        in srgb,
        var(--backgroundcolor) 80%,
        transparent 20%
    );
    display: flex;
    justify-content: center;
    align-items: center;
}
.editpage_main {
    width: clamp(25rem, 60%, 50rem);
    height: 80%;
    background-color: var(--backgroundcolor);
    padding: 1.5rem 1rem;
    border: solid 1px var(--primarycolor);
    border-radius: 0.5rem;
}
.reportStatus {
    background-color: var(--backgroundcolor);
    color: var(--fontcolor);
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}
.statustitle {
    margin-right: 1rem;
}
.response {
    position: relative;
    padding-bottom: 0.5rem;
}
.response textarea {
    position: absolute;
    top: 0px;
    left: 0px;
    border: 0;
    border-bottom: 1px solid var(--primarycolor);
    background: transparent;
    width: 100%;
    height: calc(100% - 0.25rem) !important;
    padding: 3.5rem 0 0 0;
    font-size: 1rem;
}
.response textarea:focus {
    border: none;
    outline: none;
    border-bottom: 1px solid var(--primarycolor);
}
.response label {
    position: absolute;
    top: 0.5rem;
    left: 0px;
    font-size: 1.25rem;
    pointer-events: none;
    background-color: var(--backgroundcolor);
}
.responsepic {
    width: 25%;
    cursor: pointer;
    padding-bottom: 1rem;
}
.scheduledisplayer {
    position: absolute;
    right: 1rem;
    height: 80vh;
    width: clamp(20rem, 40vw, 60rem);
    top: 1rem;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    border: 1px solid var(--primarycolor);
    box-shadow: var(--primarycolor) 0px 20px 30px -10px;
    background-color: var(--backgroundcolor);
    overflow-y: scroll;
    padding: 1rem;
}
.scheduleblock {
    width: 100%;
    border-bottom: 1px solid var(--primarycolor);
}
.schedulerow {
    display: flex;
}
.questions {
}
.schedulebt {
    position: fixed;
    top: 2rem;
    right: 2rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 2;
}
.schedulecollapse {
    width: 100%;
    overflow-y: hidden;
    height: 2rem;
    position: relative;
}
.schedulecollapse.show {
    height: auto;
}
.collapsebt {
    position: absolute;
    right: 1rem;
    cursor: pointer;
    color: var(--primarycolor);
}
