.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    background-color: color-mix(
        in srgb,
        var(--backgroundcolor) 60%,
        transparent
    ); /* 深色遮罩 */
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadein 0.5s ease;
    z-index: 3;
}
.fadeout {
    animation: fadeout 0.7s ease;
    opacity: 0;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeout {
    0% {
        opacity: 1;
    }
    30% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes show {
    0% {
        height: 0vh;
        padding: 0rem;
    }
    50% {
        padding: 2.5rem 0.5rem 2.5rem 2.5rem;
    }
    100% {
        padding: 2.5rem 0.5rem 2.5rem 2.5rem;
        height: 70vh;
    }
}

.content {
    background-color: var(--backgroundcolor);
    border: var(--primarycolor) 1px solid;
    box-shadow: 0rem 0rem 2rem var(--primarycolor);
    padding: 20px;
    border-radius: 5px;
    animation: show 1s ease;
    padding: 2.5rem 0.5rem 2.5rem 2.5rem;
    height: 70vh;
    width: 80vw;
    position: relative;
    overflow-y: scroll;
}

.bt {
    position: absolute;
    top: 1rem;
    right: 0rem;
    background-color: var(--backgroundcolor);
    border: none;
    cursor: pointer;
    opacity: 0.5;
}
.bt:hover {
    opacity: 1;
}

.eventDate {
    margin-bottom: 0.5rem;
    color: var(--fontcolor);
}
.month {
    font-size: 1.5rem;
}
.date {
    font-size: 2.5rem;
    padding-left: 0.5rem;
}

.subjectsContainer {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: flex-start;
    align-items: baseline;
}

.subjectItem {
    padding: 1rem 0rem;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    width: 20rem;
    position: relative;
}

.subjecttitle {
    margin-bottom: 1.25rem;
    width: auto;
}

.title1 {
    font-size: 1.5rem;
    font-weight: 900;
}
.title2 {
    font-size: 1rem;
}

.startExambt {
    background-color: var(--backgroundcolor);
    border: 1px solid var(--primarycolor);
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1.25rem;
}
.startExambt:hover {
    background-color: var(--primarycolor);
    color: var(--backgroundcolor);
    transform: scale(1.1);
}
.checkExambt {
    background-color: var(--backgroundcolor);
    border: 1px solid var(--alarmcolor);
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1.25rem;
    margin-left: 1rem;
}
.checkExambt:hover {
    background-color: var(--alarmcolor);
    color: var(--backgroundcolor);
    transform: scale(1.1);
}
.score {
    color: var(--primarycolor);
    margin-left: 1rem;
    font-size: 2rem;
}
