.page {
    width: 100vw;
    max-width: 100%;
    min-height: 100dvh;
    height: 100dvh;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 255px;
    background-color: var(--backgroundcolor);
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    font-size: 2rem;
}
