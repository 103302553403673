/* 基本形状样式 */
.shape {
    width: 1rem;
    height: 1rem;
    margin: 0rem;
}

/* 黄色三角形 */
.triangle {
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 1rem solid #f9bf45;
}

/* 红色圆形 */
.circle {
    border-radius: 50%;
    background-color: #d0104c;
}

/* 蓝色方形 */
.square {
    background-color: #005caf;
}
.noteradios {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.noteradio {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}
