* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    color: var(--fontcolor);
}
html {
    font-size: 16px;
    overflow: hidden;
}
@media (max-width: 600px) {
    html {
        font-size: 12px;
    }
}
@media (max-width: 325px) {
    html {
        font-size: 10px;
    }
}
body {
    margin: 0;
    font-family: "Microsoft JhengHei", "PingFang", -apple-system,
        BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.App[theme="dark"] {
    --alarmcolor: #cb4042;
    --primarycolor: #51a8dd;
    --secondarycolor: #0c1013;
    --backgroundcolor: #010202;
    --fontcolor: #c9d8e6;
}
.App[theme="light"] {
    --alarmcolor: #db4d6d;
    --primarycolor: #0089a7;
    --secondarycolor: #e0e0e0;
    --backgroundcolor: #fbfcfe;
    --fontcolor: #182f4b;
}

::-webkit-scrollbar {
    height: 0.5rem;
    width: 0.5rem;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: var(--secondarycolor);
    border-radius: 10px;
    border: 1px solid var(--primarycolor);
}
