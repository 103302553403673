hr {
    margin-top: 2rem;
    border: 1px solid var(--primarycolor);
    width: 85%;
    max-width: 400px;
    margin-bottom: 0.5rem;
}
.page {
    width: 100vw;
    max-width: 100%;
    min-height: 100dvh;
    height: 100dvh;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 255px;
    background-color: var(--backgroundcolor);
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
}
.main {
    position: relative;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 0.5rem;
    border: 2px solid var(--primarycolor);
    align-items: center;
    justify-content: center;
    background-color: var(--backgroundcolor);
    z-index: 2;
    padding: 1rem;
    padding-bottom: 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(18, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-items: center;
    box-shadow: 0rem 0.5rem 2rem
        color-mix(in srgb, var(--primarycolor) 60%, transparent);
}

.top {
    grid-area: 1 / 1 / 3 / 2;
}
.bottom {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    grid-area: 17 / 1 / 19 / 2;
}
.examname {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    font-size: 1.5rem;
    width: 100%;
    line-height: 2rem;
    gap: 1rem;
    font-weight: 900;
}
.frontline {
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 0.5rem;
    height: calc(25dvh);
    font-size: 1.25rem;
    text-align: justify;
    background-color: var(--backgroundcolor);
    resize: none;
    border: none;
    grid-area: 4 / 1 / 12 / 2;
}
.btrow {
    width: 100%;
    display: flex;
    justify-content: right;
    grid-area: 3 / 1 / 4 / 2;
}
.schedulesbt {
    width: 10rem;
    text-align: center;
    border-radius: 0.25rem;
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0.5rem;
    font-size: 1.25rem;
    margin-right: -1rem;
    margin-bottom: -1rem;
}
.schedulesbt:hover {
    cursor: pointer;
}
.head {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    font-size: 2rem;
    width: 100%;
    line-height: 2rem;
    gap: 1rem;
    font-weight: 900;
    margin-top: 1rem;
}
.icon {
    height: 2.5rem;
    fill: var(--fontcolor);
}
.svgcolor {
    fill: var(--fontcolor);
}
.bulletin {
    width: 80%;
    font-size: 1rem;
    text-align: center;
    max-width: 375px;
}
.inputs {
    width: 80%;
    max-width: 375px;
    font-size: 1.5rem;
    grid-area: 12 / 1 / 17 / 2;
}

.bt {
    width: 100%;
    margin-top: 1rem;
    border-radius: 1.5rem;
    background-color: var(--backgroundcolor);
    border: 2px solid var(--primarycolor);
    font-size: 1.5rem;
    height: 3rem;
    transition: transform 1s ease;
    box-shadow: 1px 1px 15px -2px var(--primarycolor);
    -webkit-box-shadow: 1px 1px 15px -2px var(--primarycolor);
    -moz-box-shadow: 1px 1px 15px -2px var(--primarycolor);
}
.bt:hover {
    cursor: pointer;
    background-color: var(--primarycolor);
    color: var(--backgroundcolor);
    transform: scale(1.1);
}

@media (min-width: 1055px) {
    .main {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(10, 1fr);
    }
    .top {
        grid-area: 1 / 1 / 3 / 3;
    }
    .btrow {
        grid-area: 3 / 1 / 4 / 3;
    }
    .frontline {
        grid-area: 1 / 3 / 11 / 6;
        margin-left: 1rem;
        margin: 0;
        padding-left: 1.5rem;
    }
    .inputs {
        grid-area: 4 / 1 / 9 / 3;
    }
    .bottom {
        grid-area: 9 / 1 / 11 / 3;
    }
}
.mask {
    width: 100vw;
    max-width: 100%;
    min-height: 100dvh;
    height: 100dvh;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 255px;
    background-color: color-mix(
        in srgb,
        var(--backgroundcolor) 90%,
        transparent
    );
    opacity: 1;
    position: absolute;
    overflow: hidden;
    top: 0;
    z-index: 4;
    left: 0;

    animation: fadein 0.5s ease;
}
@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeout {
    0% {
        opacity: 1;
    }
    30% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fadeout {
    animation: fadeout 0.7s ease;
    opacity: 0;
}

.schedules {
    position: relative;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 0.5rem;
    align-items: flex-start;
    gap: 1.5rem;

    width: 70vw;
    overflow-y: hidden;
    background-color: var(--backgroundcolor);
    border: var(--primarycolor) 1px solid;
    box-shadow: 0rem 0rem 2rem var(--primarycolor);
    z-index: 5;

    display: flex;
    flex-direction: column;

    height: 0vh;
    padding-top: 0;
    padding-bottom: 0;

    animation: closeschedules 1s ease;
}

@keyframes closeschedules {
    0% {
        padding: 3rem 1.5rem;
        height: 70vh;
    }
    50% {
        padding: 3rem 1.5rem;
    }
    100% {
        padding: 0rem 1.5rem;
        height: 0vh;
        padding: 0rem;
    }
}

@keyframes openschedules {
    0% {
        height: 0vh;
        padding: 0rem;
    }
    50% {
        padding: 3rem 1.5rem;
    }
    100% {
        padding: 3rem 1.5rem;
        height: 70vh;
    }
}

.showschdules {
    position: relative;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 0.5rem;
    align-items: flex-start;
    gap: 1.5rem;

    width: 70vw;
    overflow-y: auto;
    background-color: var(--backgroundcolor);
    border: var(--primarycolor) 1px solid;
    box-shadow: 0rem 0rem 2rem var(--primarycolor);
    z-index: 5;

    display: flex;
    flex-direction: column;
    animation: openschedules 1s ease;

    padding: 3rem 1.5rem;
    height: 70vh;
}
