.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    background-color: color-mix(
        in srgb,
        var(--backgroundcolor) 60%,
        transparent
    ); /* 深色遮罩 */
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadein 0.5s ease;
    z-index: 3;
}
.fadeout {
    animation: fadeout 0.7s ease;
    opacity: 0;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeout {
    0% {
        opacity: 1;
    }
    30% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes show {
    0% {
        height: 0vh;
        padding: 0rem;
    }
    50% {
        padding: 2.5rem 0.5rem 2.5rem 2.5rem;
    }
    100% {
        padding: 2.5rem 0.5rem 2.5rem 2.5rem;
        height: 70vh;
    }
}

.content {
    background-color: var(--backgroundcolor);
    border: var(--primarycolor) 1px solid;
    box-shadow: 0rem 0rem 2rem var(--primarycolor);
    padding: 20px;
    border-radius: 5px;
    animation: show 1s ease;
    padding: 2.5rem 2rem 2.5rem 2rem;
    height: 70vh;
    width: 80vw;
    position: relative;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.changepage {
    display: flex;
    gap: 1rem;
    position: relative;
}
.pagebt {
    font-size: 1.25rem;
    padding: 0.5rem 0.5rem;
    cursor: pointer;
    width: 6rem;
}
.underline {
    position: absolute;
    bottom: 0;
    height: 5px;
    background-color: var(--primarycolor);
    left: 0rem;
    width: 6rem; /* 初始寬度設置為0 */
    transition: left 0.3s ease, width 0.3s ease; /* 平滑過渡效果 */
}
.underline.right {
    left: 7rem;
}
.withnotice {
    animation: blink 1.5s 1s infinite;
}
.withnotice::after {
    content: "!";
    position: absolute;
    top: -1rem;
    right: -1rem;
    color: var(--alarmcolor);
    font-weight: bolder;
    font-size: 1.5rem;
}
@keyframes blink {
    0%,
    100% {
        transform: translateY(0%);
    }
    50% {
        transform: translateY(-20%);
    }
}
.reportpage {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.show {
    animation: fadeIn 1s ease forwards;
}
.notshow {
    animation: fadeOut 1s ease forwards;
}
@keyframes fadeIn {
    from {
        transform: translateX(200%);
        display: none;
    }
    to {
        transform: translateX(0%);
        display: flex;
    }
}

/* 定义淡出动画 */
@keyframes fadeOut {
    from {
        transform: translateX(0%);
        display: none;
    }
    to {
        transform: translateX(-200%);
        display: none;
    }
}
.bt {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    background-color: var(--backgroundcolor);
    border: none;
    cursor: pointer;
    opacity: 0.5;
}
.bt:hover {
    opacity: 1;
}
.optionrow {
    display: flex;
    font-size: 1.25rem;
    height: 1.5rem;
}
.optiontxt {
    width: 6rem;
    min-width: 6rem;
}
.select {
    background-color: var(--backgroundcolor);
    padding: 0rem 0.25rem;
    margin: 0rem 0.5rem;
    font-size: 1.25rem;
    max-width: calc(100% - 6rem);
}
.reportrow {
    font-size: 1.25rem;
}
.reportinput {
    margin-top: 1rem;
    width: 100%;
    height: calc(70vh - 25rem);
    background-color: var(--backgroundcolor);
    font-size: 1.25rem;
    padding: 1rem 1rem;
    border-radius: 0.5rem;
}
.sendbt {
    background-color: var(--backgroundcolor);
    border: 1px solid var(--primarycolor);
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin-left: auto;
    transition: all 1s ease;
    font-size: 1.25rem;
}
.sendbt:hover {
    background-color: var(--primarycolor);
    color: var(--backgroundcolor);
    transform: scale(1.1);
}
.onereport {
    font-size: 1.25rem;
    border: 1px solid var(--primarycolor);
    border-radius: 0.5rem;
    padding: 1rem;
    position: relative;
    line-height: 2rem;
}
.reporttitle {
    margin-right: 1rem;
}
.responsepic {
    width: 25%;
}
