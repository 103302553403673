.page {
    width: 100vw;
    max-width: 100%;
    min-height: 100dvh;
    height: 100dvh;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 255px;
    background-color: var(--backgroundcolor);
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    gap: 4rem;
}

.scheduleblock {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 创建七列，每列宽度相等 */
    grid-gap: 1.25rem; /* 可选的间隔 */
    max-width: 80vw;
    max-height: 80vh;
}
.showdatabt {
    display: none;
}
.btrow {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 2rem;
}
.reportbt {
    font-size: 1.25rem;
    color: var(--alarmcolor);
    text-decoration: underline;
    cursor: pointer;
    display: inline;
}
@media (min-width: 1055px) {
    .scheduleblock {
        grid-template-columns: repeat(7, 1fr);
    }
}
@media (max-width: 960px) {
    .page {
        gap: 0;
        justify-content: flex-start;
        overflow: scroll;
        flex-direction: column;
    }
    .part1 {
        display: flex;
        flex-direction: column;
        min-height: 100dvh;
        height: 100dvh;
        justify-content: center;
    }
    .showdatabt {
        display: block;
        font-size: 1.25rem;
        color: var(--primarycolor);
        text-decoration: underline;
        cursor: pointer;
    }
}
.examname {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
}
.unread {
    position: relative;
    animation: blink 1.5s 1s infinite;
}
.unread::after {
    content: "!";
    position: absolute;
    top: -1rem;
    right: -1rem;
    color: var(--alarmcolor);
    font-weight: bolder;
    font-size: 1.5rem;
}
@keyframes blink {
    0%,
    100% {
        transform: translateY(0%);
    }
    50% {
        transform: translateY(-20%);
    }
}
