.body {
    width: 100vw;
    max-width: 100%;
    min-height: 100dvh;
    height: 100dvh;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 255px;
    background-color: var(--backgroundcolor);
    position: relative;
    overflow: hidden;
}

.main {
    position: relative;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 0.5rem;
    border: 2px solid var(--primarycolor);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--backgroundcolor);
    z-index: 2;
    padding: 2rem;
    padding-bottom: 4rem;
}

.head {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6rem;
    font-size: 2rem;
    width: 100%;
    line-height: 6rem;
    gap: 1rem;
    font-weight: 900;
}
.icon {
    height: 2rem;
    fill: var(--fontcolor);
}
.bulletin {
    width: 80%;
    font-size: 1rem;
    text-align: center;
    max-width: 375px;
}
.inputs {
    width: 80%;
    max-width: 375px;
    font-size: 1.5rem;
}

.bt {
    width: 100%;
    margin-top: 1rem;
    border-radius: 1.5rem;
    background-color: var(--backgroundcolor);
    border: 2px solid var(--primarycolor);
    font-size: 1.5rem;
    height: 3rem;
    transition: transform 1s ease;
}
.bt:hover {
    cursor: pointer;
    background-color: var(--primarycolor);
    color: var(--backgroundcolor);
    transform: scale(1.1);
}

.input-container {
    margin-top: 1rem;
    position: relative;
    height: 3rem;
}
.input-container label {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 1.5rem;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
}
.input-container input {
    position: absolute;
    top: 0px;
    left: 0px;
    border: 0;
    border-bottom: 1px solid var(--primarycolor);
    background: transparent;
    width: 100%;
    padding: 0.5rem 0 5px 0;
    font-size: 1.5rem;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
.input-container input:focus {
    border: none;
    outline: none;
    border-bottom: 1px solid var(--primarycolor);
}
.input-container input:focus ~ label,
.input-container input:valid ~ label {
    top: -1rem;
    font-size: 1rem;
}
