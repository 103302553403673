.scrolltable {
    overflow: auto;
}
.table {
    border-collapse: collapse;
    margin-top: 2rem;
}
.row {
    border-bottom: 1px solid var(--primarycolor);
}
.fblock {
    border-right: 1px solid var(--primarycolor);
}
.block {
    width: 5rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}
.link {
    color: var(--primarycolor);
    text-decoration: underline;
    cursor: pointer;
}
.noanswer {
    background-color: var(--alarmcolor);
}
.alarm {
    margin-top: 1rem;
    margin-left: 1rem;
}
@media (max-width: 860px) {
    .scrolltable {
        position: relative;
        overflow: auto;
        max-width: 95vw;
        white-space: nowrap;
    }
    .table {
        border-collapse: collapse;
    }
    .ftd {
        background-color: var(--backgroundcolor) !important;
        position: sticky !important;
        left: 0px;
        z-index: 2;
        width: 5rem;
        min-width: 5rem;
        max-width: 5rem;
    }
}
