.controlbts {
    display: flex;
}
.controlbt {
    background-color: var(--backgroundcolor);
    border-color: var(--fontcolor);
    padding: 0.25rem 0.5rem 0.25rem 0.25rem;
    margin-left: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    border-radius: 8%;
}
.pic1 {
    margin-right: 0.25rem;
}
