.schedule {
    transform: translateY(0%);
    transition: transform 1s ease-out;
}
.hidden {
    transform: translateY(2000%);
}

.date {
    font-size: 1.5rem;
}
