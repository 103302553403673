.schedule {
    transform: translateY(0%);
    transition: transform 1s ease-out;
}
.hidden {
    transform: translateY(2500%);
}

.date {
    font-size: 1rem;
    width: 4rem;
    height: 4rem;
    /* border: 1px solid var(--fontcolor);
    border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: baseline;
    opacity: 0.4;
    cursor: default;
}

.havetest {
    opacity: 1;
    transition: all 0.5s ease;
}
.havetest:hover {
    cursor: pointer;
    transform: scale(1.2);
}

.undone {
    /* animation: blink 1.5s 1s infinite; */
}
.undone::after {
    content: "!";
    position: absolute;
    top: -1rem;
    right: -1rem;
    color: var(--alarmcolor);
    font-weight: bolder;
    font-size: 1.5rem;
}
@keyframes blink {
    0%,
    100% {
        transform: translateY(0%);
    }
    50% {
        transform: translateY(-20%);
    }
}

@keyframes opendetails {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    20% {
        transform: scale(0.8);
    }
    60% {
        opacity: 1;
    }
    100% {
        transform: scale(10);
    }
}

.details {
    opacity: 0;
    animation: opendetails 1s ease;
}
