.main {
    border: 1px solid var(--primarycolor);
    height: calc(100vh - 22rem);
    width: 95vw;
    max-width: 860px;
    margin-top: 1rem;
    border-radius: 0.5rem;
    padding: 0.25rem;
}
.toprow {
    height: 3rem;
    line-height: 3rem;
    vertical-align: bottom;
    border-bottom: 1px solid var(--primarycolor);
    padding-left: 0.5rem;
}
.scrollable {
    overflow-y: scroll;
    height: calc(100% - 3rem);
}
.table {
    border-collapse: collapse;
}
.row {
    border-bottom: 1px solid var(--primarycolor);
}
.ansblock {
    min-width: 5rem;
    text-align: center;
    border-right: 1px solid var(--primarycolor);
}
.qblock {
    padding: 1rem 1rem;
    line-height: 2rem;
    max-width: calc(100vw - 7rem);
    position: relative;
}
.optionpic {
    margin-left: 1rem;
    width: 80%;
    max-width: 80%;
}
.rightQ {
    color: var(--primarycolor);
}
.wrongQ {
    color: var(--alarmcolor);
}
@media (max-width: 860px) {
    .main {
        height: calc(100vh - 25rem);
    }
}
