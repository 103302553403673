.allstatistic {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.backbt {
    display: none;
}
@media (max-width: 960px) {
    .allstatistic {
        min-height: 100dvh;
        height: 100dvh;
        justify-content: center;
        z-index: 2;
        background-color: var(--backgroundcolor);
    }
    .backbt {
        display: block;
        margin-left: auto;
        margin-top: -10rem;
        font-size: 1.25rem;
        color: var(--primarycolor);
        text-decoration: underline;
        cursor: pointer;
    }
}
.statisticblock {
    display: grid;
    padding-bottom: 0.5rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    padding-left: 1rem;
    border-left: 0.5rem solid var(--primarycolor);
    width: 25rem;
    transition: all 1s ease;
    transform: translateY(1000%);
}

.show {
    transform: translateY(0%);
}

.statistictitle {
    font-size: 1.5em;
    grid-area: 1 / 1 / 2 / 4;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.statisticmain {
    font-size: 2rem;
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.statisticsub {
    font-size: 1.25rem;
    grid-area: 2 / 2 / 3 / 4;
    display: flex;
    align-items: flex-end;
    padding-left: 1rem;
}
.statisticsmall {
    font-size: 1.25rem;
    margin-left: 0.5rem;
}
